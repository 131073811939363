<template>
    <div v-if="settingData">
        <div class="d-flex">
            <h2 class="d-none d-sm-inline">Setting</h2>
        </div>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col cols="12">
                            <hr />
                        </b-col>
                        <!-- Field: Company Name En-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Name" rules="required">
                                <b-form-group class="required" label="Company Name" label-for="name"
                                    label-cols-md="4">
                                    <b-form-input id="name"
                                        :value="getSettingValue('name')"
                                        @input="(val) => {
                                            const index = findSettingIndex('name');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Company Name CN-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Phone" rules="required">
                                <b-form-group class="required" label="Company Phone" label-for="phone"
                                    label-cols-md="4">
                                    <b-form-input id="phone"
                                        :value="getSettingValue('phone')"
                                        @input="(val) => {
                                            const index = findSettingIndex('phone');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Email -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Email" rules="required">
                                <b-form-group class="required" label="Email" label-for="email"
                                    label-cols-md="4">
                                    <b-form-input id="email"
                                        :value="getSettingValue('email')"
                                        @input="(val) => {
                                            const index = findSettingIndex('email');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Field: Company Address Eng-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Address" rules="required">
                                <b-form-group class="required" label="Company Address"
                                    label-for="address" label-cols-md="4">
                                    <b-form-input id="address"
                                        :value="getSettingValue('address')"
                                        @input="(val) => {
                                            const index = findSettingIndex('address');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Field: WhatsApp -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="WhatsApp" rules="required">
                                <b-form-group class="required" label="WhatsApp" label-for="whatsapp"
                                    label-cols-md="4">
                                    <b-form-input id="whatsapp"
                                        :value="getSettingValue('whatsapp')"
                                        @input="(val) => {
                                            const index = findSettingIndex('whatsapp');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Field: Instagram -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Instagram" rules="required">
                                <b-form-group class="required" label="Instagram" label-for="instagram"
                                    label-cols-md="4">
                                    <b-form-input id="instagram"
                                        :value="getSettingValue('instagram')"
                                        @input="(val) => {
                                            const index = findSettingIndex('instagram');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Field: Facebook -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Facebook" rules="required">
                                <b-form-group class="required" label="Facebook" label-for="facebook"
                                    label-cols-md="4">
                                    <b-form-input id="facebook"
                                        :value="getSettingValue('facebook')"
                                        @input="(val) => {
                                            const index = findSettingIndex('facebook');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Field: YouTube -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="YouTube" rules="required">
                                <b-form-group class="required" label="YouTube" label-for="youtube"
                                    label-cols-md="4">
                                    <b-form-input id="youtube"
                                        :value="getSettingValue('youtube')"
                                        @input="(val) => {
                                            const index = findSettingIndex('youtube');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- Field: Opening Hours -->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Opening Hours" rules="required">
                                <b-form-group class="required" label="Opening Hours" label-for="open_hours"
                                    label-cols-md="4">
                                    <b-form-input id="open_hours"
                                        :value="getSettingValue('open_hours')"
                                        @input="(val) => {
                                            const index = findSettingIndex('open_hours');
                                            if (index !== -1) settingData[index].value = val;
                                        }" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                    </b-row>
                    <b-col cols="12">
                        <hr />
                    </b-col>
                    <!-- Form Actions -->

                    <div class="d-flex justify-content-end mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="ml-2"
                            type="submit">
                            Edit
                        </b-button>
                    </div>
                </b-form>
            </b-overlay>
        </validation-observer>
    </div>
    <div v-else>
        <p>Loading settings...</p>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    BTabs,
    BTab,
    VBTooltip,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, between, integer } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import ability from "@/libs/acl/ability";
import VueHtml2pdf from "vue-html2pdf";
import { mapFields } from "vuex-map-fields";
import { i18n } from "@/main";

export default {
    data() {
        return {
            required,
            between,
            integer,
            contentRendered: false,
            progress: 0,
            // controlValue: {
            //     showLayout: true,
            //     floatLayout: true,
            //     enableDownload: true,
            //     previewModal: true,
            //     paginateElementsByHeight: 11000,
            //     manualPagination: false,
            //     filename: 'Invoice',
            //     pdfQuality: 4,
            //     pdfFormat: 'a4',
            //     pdfOrientation: 'portrait',
            //     pdfContentWidth: '800px',
            //     minHeight: 12,
            //     showZero: false,
            // },
        };
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        flatPickr,
        BFormInput,
        vSelect,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormDatepicker,
        BInputGroupAppend,
        BInputGroup,
        BFormInvalidFeedback,
        BOverlay,
        BTabs,
        BTab,
        VBTooltip,
        BButton,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        VueHtml2pdf,
    },
    methods: {
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`);
        },

        startPagination() {
            console.log(`PDF has started pagination`);
        },

        hasPaginated() {
            console.log(`PDF has been paginated`);
        },
        domRendered() {
            this.contentRendered = true;
        },

        onSubmit() {
            var data = this.settingData;
            this.$swal({
                title: "Are you sure",
                text: "",
                showCancelButton: true,
                confirmButtonText: "Save",
                cancelButtonText: "Cancel",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.loading = true;
                    store
                        .dispatch("setting-setting/updateSetting", data)
                        .then((response) => {
                            this.loading = false;
                            this.$swal({
                                text: response.data.message,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                            this.$emit("refetch-data");
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.$swal({
                                text: JSON.stringify(error.response.data.message),
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
    },
    computed: {
        ...mapFields(["controlValue"]),
    },
    props: {
        settingData: {
            type: Array,
            required: true,
        },
    },
    created() {
        this.$watch("$route.name", (name) => { });
    },
    setup(props, { emit }) {
        const loading = ref(false);

        // Add safety check to findSettingIndex
        const findSettingIndex = (key) => {
            if (!props.settingData) return -1;
            return props.settingData.findIndex((obj) => obj.key_set === key);
        };

        // Add computed property for safe value access
        const getSettingValue = (key) => {
            const index = findSettingIndex(key);
            return index !== -1 ? props.settingData[index]?.value : '';
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation();

        return {
            findSettingIndex,
            getSettingValue,
            loading,
            refFormObserver,
            getValidationState,
            resetForm,
            ability,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.required>label::after {
    content: " **";
    color: red;
}

.vs__selected path {
    fill: white;
}

.v-select {

    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;

        .dark-layout & {
            background-color: unset;
        }
    }
}
</style>