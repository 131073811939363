import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/settings/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSetting(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/settings', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('/settings', { settings: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}