var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settingData)?_c('div',[_vm._m(0),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Company Name","label-for":"name","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"name","value":_vm.getSettingValue('name')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('name');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Company Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Company Phone","label-for":"phone","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"phone","value":_vm.getSettingValue('phone')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('phone');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Email","label-for":"email","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"email","value":_vm.getSettingValue('email')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('email');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Company Address","label-for":"address","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"address","value":_vm.getSettingValue('address')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('address');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"WhatsApp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"WhatsApp","label-for":"whatsapp","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"whatsapp","value":_vm.getSettingValue('whatsapp')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('whatsapp');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Instagram","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Instagram","label-for":"instagram","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"instagram","value":_vm.getSettingValue('instagram')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('instagram');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Facebook","label-for":"facebook","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"facebook","value":_vm.getSettingValue('facebook')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('facebook');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"YouTube","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"YouTube","label-for":"youtube","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"youtube","value":_vm.getSettingValue('youtube')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('youtube');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Opening Hours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Opening Hours","label-for":"open_hours","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"open_hours","value":_vm.getSettingValue('open_hours')},on:{"input":function (val) {
                                        var index = _vm.findSettingIndex('open_hours');
                                        if (index !== -1) { _vm.settingData[index].value = val; }
                                    }}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Edit ")])],1)],1)],1)]}}],null,false,1071605498)})],1):_c('div',[_c('p',[_vm._v("Loading settings...")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('h2',{staticClass:"d-none d-sm-inline"},[_vm._v("Setting")])])}]

export { render, staticRenderFns }